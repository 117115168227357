import React from 'react'
import { compose, pure } from 'recompose'
import { withNamespaces } from 'react-i18next'
import SetLanguageFromPage from '../../config/i18n/SetLanguageFromPage'

import SecondaryLayout from '../../components/layouts/SecondaryLayout'
import GarantieEtProcessus from '../../components/methode-raypath/GarantieEtProcessus'

const GarantieEtProcessusPage = ({ t }) => (
  <SecondaryLayout subtitle={t('titre')} page="ComponentMethodeRaypathGarantieEtProcessus">
    <GarantieEtProcessus />
  </SecondaryLayout>
)

const echance = compose(
  pure,
  SetLanguageFromPage(),
  withNamespaces('garantie-et-processus-de-recyclage')
)

export default echance(GarantieEtProcessusPage)
